<template>
    <v-card>
        <v-card-title>
          <span class="headline">Editar información</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre de usuario"
                  :value="getUsername()"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Contraseña anterior"
                  type="password"
                  required
                  v-model="Oldpassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Contraseña nueva"
                  type="password"
                  required
                  v-model="Newpassword"
                ></v-text-field>
              </v-col>
              
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogState(false)"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="changePassword({ oldpassword: Oldpassword, newpassword: Newpassword, username: getUsername() })"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
</template>
<script>
import { mapMutations, mapActions } from 'vuex';
export default {
    data(){
      return{
        Oldpassword: '',
        Newpassword: '',
      }
    },
    methods:{
        ...mapActions("ticketsForm", ["changePassword"]),
        ...mapMutations('ticketsForm',['dialogState']),
        getUsername(){
            return localStorage.getItem('username')    
        }
    }
}
</script>