<template>
  <div class="home">
    <Navbar/>
    <v-container>
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <Form />
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <v-expansion-panels v-model="panel" accordion class="mt-8">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template >
                    <v-row no-gutters>
                      <v-col cols="4">
                        Historial de tickets
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Table />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template >
                    <v-row no-gutters>
                      <v-col cols="4">
                        Mis dispositivos
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                 <DevicesTable />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            

              
            
        </v-col>
      </v-row>
    </v-container>
    <DialogReport/>
    <FAB :buttons="[
      {color: 'primary',
      fn: openServiceReportDialog,
      icon: 'mdi-file-download',
      name: 'Reportes de servicios'}
      ]"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Form from "@/components/Form.vue";
import Table from "@/components/Table.vue";
import DevicesTable from "@/components/DevicesTable.vue"
import Navbar from "@/components/navbar.vue"
import FAB from '../components/FAB'
import DialogReport from '../components/dialogReports'
import {mapActions} from 'vuex'
export default {
  name: "Home",
  components: {
    Form,
    Table,
    Navbar,
    FAB,
    DialogReport,
    DevicesTable
  },
  methods:{
    ...mapActions('Reports',['openServiceReportDialog'])
  }
};
</script>
