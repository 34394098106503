<template>
  <div>
    <v-toolbar color="black">
      <v-img
        alt="Radiotrunk Logos"
        src="../assets/Logo-Radio-Trunk_blanco.png"
        width="150"
        max-width="150"
      />
      <v-spacer></v-spacer>
      <v-menu
          bottom
          min-width="200px"
          rounded
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              x-large
              v-on="on"
            >
              <v-avatar
                color="primary"
                size="48"
              >
                <span class="white--text headline">{{ getInitial() }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar
                  color="primary"
                >
                  <span class="white--text headline">{{ getInitial() }}</span>
                </v-avatar>
                <p>{{ getUsername() }}</p>
                <v-divider class="my-3"></v-divider>
                <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="600px"
                >
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  rounded
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogState(true)"
                >
                  Editar Cuenta
                </v-btn>
                
                </template>
                <EditAccount/>
                </v-dialog>
                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="logout"
                >
                  Cerrar Sesion
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
    </v-toolbar>
  </div>
</template>

<script>
import EditAccount from  './EditAccount'
import { mapMutations, mapState } from 'vuex' 

export default {
  components: {
      EditAccount,
  },
  computed: {
      ...mapState("ticketsForm", ["dialog"])
  },
  methods: {
      ...mapMutations("ticketsForm", ["dialogState"]),
      getInitial(){
      if(localStorage.username){
          let aux = localStorage.getItem('username')
          return aux.charAt(0).toUpperCase()
      }
      return 'x'
    },
    async logout(){
        if(localStorage.token){
          localStorage.removeItem('token');
          window.location.reload()
        }
        else{
          window.location.reload()
        }
      },
    getUsername(){
        return localStorage.getItem('username')
    }
  }
};
</script>