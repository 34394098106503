<template>
  <v-card max-width="800" elevation="0">
    <v-card-title>
      <span class="headline">Generar ticket</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row v-if="checkLS()">
          <v-col cols="12">
            <v-switch
              color="primary"
              :input-value="multipleTickets"
              label="Multiples tickets"
              :false-value="false"
              :true-value="true"
              @change="changeMultipleTicketsSwitch($event)"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <span>Datos del dispositivo</span>
          <v-col cols="12">
            <Autocomplete
              :Value="device_id"
              :Fn="setDeviceId"
              :Items="devices"
              :Rules="[]"
              :Multiple="multipleTickets"
              :GetValues="getDevices"
              Label="Dispositivo"
            />
          </v-col>
          <v-col>
            <v-chip color="red" v-if="SelectedDeviceHasTicket === true" dark
              >Este dispositivo ya tiene un ticket activo</v-chip
            >
          </v-col>
        </v-row>
        <v-row v-if="SelectedDeviceHasTicket === false">
          <span>Datos de contacto</span>
          <v-col cols="12">
            <TextField
              :Value="phone_number"
              :Fn="setPhoneNumber"
              Label="Número de telefono"
              Type="number"
            />
          </v-col>
          <v-col cols="12">
            <TextField
              :Value="email"
              :Fn="setEmail"
              Label="Correo electronico"
              Type="text"
            />
          </v-col>
          <span>Reporte escrito y multimedia</span>
          <v-col cols="12">
            <TextArea
              :Value="description"
              Label="Descripción de las fallas de su dispositivo*"
              :Fn="setDescription"
            />
          </v-col>
          <v-col cols="12">
            <FileInput
              :Value="files"
              PlaceHolder="Muéstranos las fallas de tu radio"
              DataType="image/jpeg,image/jpg,video/mp4"
              :OnChange="setMultimedia"
            />
          </v-col>
        </v-row>
      </v-container>
      <small>*Campos obligatorios</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="
          generateTicket();
          DeviceHasActiveTickets();
fetchHistoricalTickets();
        "
        v-if="SelectedDeviceHasTicket === false"
      >
        Generar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { mapActions, mapMutations, mapState } from "vuex";
import FileInput from "./FileInput";
import Autocomplete from "./Autocomplete";
import TextField from "./TextField";
import TextArea from "./TextArea";
export default {
  components: {
    Autocomplete,
    TextField,
    TextArea,
    FileInput,
  },
  computed: {
    ...mapState("ticketsForm", [
      "multipleTickets",
      "device_id",
      "files",
      "phone_number",
      "email",
      "description",
      "devices",
      "deviceSearchTerm",
      "SelectedDeviceHasTicket",
    ]),
  },
  methods: {
    checkLS(){
      if(localStorage.isAdmin==1){
        return true
      }else{
        return false
      }
    },
    ...mapActions("table", ["fetchHistoricalTickets"]),
    ...mapMutations("ticketsForm", [
      "setMultimedia",
      "setPhoneNumber",
      "setEmail",
      "setDescription",
      "setDeviceId",
      "afterGenerateTicket",
      "changeMultipleTicketsSwitch",
    ]),
    ...mapActions("ticketsForm", [
      "generateTicket",
      "getDevices",
      "countDevices",
      "DeviceHasActiveTickets",
    ]),
  },
  created() {
    this.getDevices();
  },
};
</script>