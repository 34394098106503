<template>
<v-dialog
        :value="reportDialog"
        persistent
        max-width="600px"
        
      >
    <v-card >
        <v-card-title>
          <span class="headline">Descargar Reporte</span>
        </v-card-title>
        <RadioGroup
          class="ml-2"
          :Options="[{value:'EXCEL',label:'Excel'},{value:'PDF',label:'PDF'}]" 
          :Value='typeOfFile' 
          :Fn="updateTypeOfFile"
        />
        <v-container>
            <v-row>
                <v-col
                    cols="12"
                >
                    <Autocomplete  
                        :Value="device_id" 
                        :Fn="setDeviceId" 
                        :Items="devices" 
                        :Rules="[]"
                        :GetValues='getDevices'
                        Label="Dispositivo"
                        :multiple="true"
                        v-if="!allDevices"
                    />
                </v-col>
                <v-col cols="12" align-self="align" class="d-flex justify-center">
                  <v-switch
                    :value="allDevices"
                    @change="selectAllDevices($event)"
                    label="Reporte de todos los dispositivos"
                    color="success"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col cols="6">
                    <DatePicker Label="De" :Fn="updateDateInitReport" :DateValue="dateInitReport.date" :Dialog="dateInitReport.dialog"/>
                </v-col>
                <v-col cols="6">
                    <DatePicker Label="Hasta" :Fn="updateDateEndReport" :DateValue="dateEndReport.date" :Dialog="dateEndReport.dialog"/>
                </v-col>
                <v-col cols="12" align="center">
                    <v-btn
                        v-if="device_id"
                        rounded
                        color="primary"
                        dark
                        center
                        @click="getReportByDevice()"
                    >
                        Generar reporte
                    </v-btn>
                </v-col>
            </v-row>
        </v-container> 
        
        <v-card-text>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeServiceReportDialog()"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import Autocomplete from '../components/Autocomplete'
import RadioGroup from '../components/RadioGroup'
import DatePicker from '../components/DatePicker.vue'
import { mapActions,mapMutations,mapState} from "vuex";
export default {
    name: "cuestionarioReporte",
    components:{
      Autocomplete,
      RadioGroup,
      DatePicker
    },
    computed:{
      ...mapState('Reports',['typeOfFile','reportDialog','dateInitReport','dateEndReport']),
      ...mapState('ticketsForm',['device_id','devices','allDevices'])
    },
    methods: {
      ...mapMutations("Reports", [
        'updateTypeOfFile',
        'updateDateInitReport',
        'updateDateEndReport'
      ]),
      ...mapActions('Reports',['closeServiceReportDialog','getReportByDevice']),
      ...mapActions("ticketsForm", [
      "getDevices",
      "selectAllDevices"
      ]),
      ...mapMutations("ticketsForm", [
      "setDeviceId",
    ]),
    
    }
}
</script>