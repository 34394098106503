var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" Historico de tickets "),_c('v-spacer'),_c('v-text-field',{attrs:{"value":_vm.search,"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"input":_vm.asignSearchTerm}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.items_per_page,"loading":_vm.loading,"loading-text":_vm.loading_text,"item-key":_vm.item_key,"no-data-text":_vm.no_data_text,"search":_vm.search,"footer-props":{
        itemsPerPageOptions:[10]
      }},scopedSlots:_vm._u([{key:"item.init_date",fn:function(ref){
      var item = ref.item;
return [(item.init_date)?_c('span',[_vm._v(_vm._s(item.init_date.substr(0,10)))]):_vm._e()]}},{key:"item.end_date",fn:function(ref){
      var item = ref.item;
return [(item.end_date)?_c('span',[_vm._v(_vm._s(item.end_date.substr(0,10)))]):_vm._e()]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"color":_vm.getColorChip(item.status),"text-color":_vm.getColorText(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }