<template>

    <v-card elevation="0">
      <v-card-title>
        Historico de tickets
        <v-spacer></v-spacer>
        <v-text-field
          :value="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @input="asignSearchTerm"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="items_per_page"
        :loading="loading"
        :loading-text="loading_text"
        :item-key="item_key"
        :no-data-text="no_data_text"
        :search="search"
        :footer-props="{
            itemsPerPageOptions:[10]
          }"
      >
      <template  v-slot:item.init_date="{ item }">
          <span v-if="item.init_date">{{item.init_date.substr(0,10)}}</span>
          
        </template>
        <template  v-slot:item.end_date="{ item }">
          <span v-if="item.end_date">{{item.end_date.substr(0,10)}}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <div>
            <v-chip
              :color="getColorChip(item.status)"
              :text-color="getColorText(item.status)"
            >
              {{item.status}}
            </v-chip>
          </div>
        </template>
        
      </v-data-table>
    </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("table", ["headers","items","items_per_page","loading",
                        "loading_text","item_key","no_data_text","search"]),
  },
  methods:{
      ...mapMutations("table", ["asignSearchTerm"]),
      ...mapActions("table", ["fetchHistoricalTickets"]),
    getColorText(status){
      if(status==="En Revision")
      {
        return "black"
      }
      return "white"
    },
    getColorChip(status){
      switch(status){
        case "Generado":
          return "blue";
        case "En Revision":
        return "yellow";
        case "Resuelto":
          return "green";
        case "Cancelado":
          return "red";
      }
    },
  },
  created(){
    this.fetchHistoricalTickets()
  }
};
</script>