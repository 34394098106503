<template>

    <v-card elevation="0">
      <v-card-title>
        Tus Dispositivos
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="devicesTable"
        :items-per-page="10"
        item-key="device_id"
        no-data-text="Empty"
        :footer-props="{
            itemsPerPageOptions:[10]
          }"
      > 
      </v-data-table>
    </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState("ticketsForm", ["headers","devicesTable"]),
  },
  methods:{
      ...mapActions("ticketsForm", ["getAllDevices"]),
  },
  created(){
    this.getAllDevices()
  }
};
</script>